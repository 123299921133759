@import '~antd/dist/antd.css';
/* @import '~antd/dist/antd.dark.css'; */

.app-root {
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
}

/* Prevents Safari Zoom on Input Click */
input[type='text'] {
  font-size: 16px;
}

* {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box;
}

* {
  font-family: Poppins !important;
}

.fade-in {
  animation: fadeIn 600ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html,
body {
  background-color: #f7f8fa;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
