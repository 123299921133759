.ant-tabs-tab {
  flex: 1;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.ant-tabs-nav-list {
  width: 100%;
}

.login-container {
  margin: 0 auto;
  max-width: 90%;
  width: 400px;
}
