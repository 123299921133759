.content-placeholder {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0px;
  transform-origin: top center;
}

section {
  overflow: hidden;
}

.form-menu-item {
  margin-bottom: 24px;
  font-size: 16px;
}
.form-menu-item:last-child {
  margin-bottom: 0px;
}

.form-menu-category-header {
  font-weight: bold;
  font-size: 16px;
}
