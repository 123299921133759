.pac-item {
  /* height: 32px; */
  cursor: default;
  padding: 5px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* line-height: 30px; */
  text-align: left;
  /* border-top: 1px solid #e6e6e6; */
  font-size: 11px;
  color: #999;
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto;
}

.pac-icon-marker {
  display: none;
}

.hdpi.pac-logo:after {
  display: none;
}
